// 
// structure-rtl.scss
//



// topbar.scss


.logo-box{
    float: right;
}

.navbar-custom {
    padding: 0px 0px 0px 10px;

    .topnav-menu {
        >li {
            float: right;
        }

        .nav-link {
            direction: ltr;
        }
    }
    
    .app-search{
        margin-left: 20px;
        .form-control{
            padding-right: 20px;
            padding-left: 0;
            border-radius: 0 30px 30px 0;
        }

        .input-group-append {
            margin-right: 0;
        }
        .btn{
            border-radius: 30px 0 0 30px;
        }
    }
}


/* Notification */
.notification-list {

    .noti-icon-badge {
        left: 14px;
        right: auto;
    }

    .notify-item {

        .notify-icon {
            float: right;
            margin-left: 10px;
            margin-right: 0;
        }

        .notify-details {
            margin-left: 0;
            margin-right: 45px;
        }
    }

    .profile-dropdown {
        i {
            margin-left: 5px;
            margin-right: 0px;
            float: right;
        }
    }
}

// page-title

.page-title-box {
    .page-title-right {
        float: left;
    }
}


// Left-sidebar

.content-page {
    margin-right: $leftbar-width;
    margin-left: 0;
}

// Sidebar
#sidebar-menu {
    >ul {
        >li {
            >a {
                i {
                    margin: 0 3px 0 10px;
                }

                .drop-arrow {
                    float: left;

                    i {
                        margin-left: 0;
                    }
                }
            }

            >ul {
                padding-right: 40px;
                padding-left: 0;

                ul {
                    padding-right: 20px;
                    padding-left: 0;
                }
            }
        }
    }

    .menu-arrow {
        left: 20px;
        right: auto;

        &:before {
            content: "\F141";
        }
    }

    li.mm-active {
        >a {
            >span.menu-arrow {
                transform: rotate(-90deg);
            }
        }
    }
}

// Enlarge menu
.enlarged {

    // Side menu
    .left-side-menu {

        // Sidebar Menu
        #sidebar-menu {

            >ul {
                >li {

                    >a {
                        i {
                            margin-left: 20px;
                            margin-right: 5px;
                        }

                        span {
                            padding-right: 25px;
                            padding-left: 0;
                        }
                    }

                    &:hover {

                        >ul {
                            right: $leftbar-width-collapsed;
                            left: auto;
                        }
                    }
                }

                ul {

                    li {
                        &:hover {
                            >ul {
                                right: 190px;
                                margin-top: -36px;
                            }
                        }

                        >a {
                            span.pull-right {
                                left: 20px;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    // Navbar

    .navbar-custom{
        right: 0px;
    }

    // Content Page
    .content-page {
        margin-right: $leftbar-width-collapsed !important;
        margin-left: 0 !important;
    }

    //Footer
    .footer {
        left: 0 !important;
        right: $leftbar-width-collapsed !important;
    }
}

@include media-breakpoint-down(sm) {

    .content-page,
    .enlarged .content-page {
        margin-right: 0 !important;
    }
}

@media (min-width: 1025px){
    .navbar-custom {
        .button-menu-mobile{
            margin-left: 0;
            margin-right: 8px;
        }
    }
}

/* =============
  Small Menu
============= */

.left-side-menu-sm {

    .left-side-menu {
        #sidebar-menu {
            >ul {
                ul {
                    padding-right: 0;
                }
            }
        }

        &+.content-page {
            margin-right: $leftbar-width-sm;
            margin-left: 0;
        }

        +.content-page .footer {
            left: auto;
            right: $leftbar-width-sm;
        }
    }
}

.enlarged.left-side-menu-sm {
    #wrapper {
        .left-side-menu {
            text-align: right;

            ul {
                li {
                    a {
                        i {
                            margin-right: 3px;
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }
}

// footer.scss

.footer {
    left: 0;
    right: $leftbar-width;
}

@include media-breakpoint-down(sm) {
    .footer {
        right: 0 !important;
    }
}

// right-sidebar.scss
//

.right-bar {
    float: left !important;
    left: -($rightbar-width + 10px);
    right: auto;
}

.right-bar-enabled {
    .right-bar {
        left: 0;
        right: auto;
    }
}

.activity-widget{
    .activity-list{
        border-left: 0;
        border-right: 2px dashed $gray-400;
        padding-right: 24px;
        padding-left: 0px;
        &::after{
            right: -7px;
        }
    }
}

// Topbar light

.topbar-light {
    .navbar-custom{
        box-shadow: -$leftbar-width 1px 0 0 $gray-100;
    }
}   

.enlarged{
    &.left-side-menu-dark{
        #wrapper .navbar-custom{
            box-shadow: -$leftbar-width-collapsed  1px 0 0 $gray-100;
        }
    }
}